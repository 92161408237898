<template>
  <div id="app" style="padding:15px; overflow:auto; width:calc(100% - 30px)">
    <loading :loading="loading" :text="loadingText"></loading>
    <div
        class="div-left"
        :style="{
          position:'relative',
          backgroundImage: 'url(' + (activity.picUrl ? activity.picUrl : '/img/bg_poster.png') + ')',
          backgroundSize:'100% 100%',
          width:posterWidth+'px',
          height:posterHeight+'px',
        }"
        @mousemove="move($event)"
    >
        <img
            id="moveDiv"
            :style="{width:qrCode.width+'px',height:qrCode.height+'px', left:qrCode.left+'px', top:qrCode.top+'px',position: 'absolute'}"
            src="/img/qrcode.png" fit="fill"
            @mousedown="down($event)"
            @mouseup="up($event)"
            draggable="false"
            :class="qrCode.class"
        />
    </div>
    <div class="div-right">
      <el-form ref="form" :model="activity"  label-width="100px">
        <el-form-item label="活动周期" :required="true">
          <el-date-picker
              :disabled="isDateTimeDisabled"
              v-model="dateTime"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="活动名称" :required="true">
          <el-input type="text"  v-model="activity.name" placeholder="请输入"></el-input>
        </el-form-item>
        <course-form-item-single ref="courseFormItemSingle"></course-form-item-single>
        <el-form-item label="奖励比例" :required="true">
          <el-input type="number" v-model="activity.rewardRate" placeholder="请输入百分比（%）"></el-input>
        </el-form-item>
        <el-form-item label="分销类型" :required="true">
          <el-radio v-model="activity.type" :label="activityTypeEnum.PROMOTER">推广员分销</el-radio>
          <el-radio v-model="activity.type" :label="activityTypeEnum.FISSION">裂变分销</el-radio>
        </el-form-item>
        <el-form-item label="分销海报" prop="picUrl" required="required">
          <el-upload :action="uploadUrl" :show-file-list="false" :accept="'image/*'"  :on-success="handleSuccess" style="float: left;height: 40px">
            <el-button type="primary">上传 <i class="el-icon-upload el-icon-right"></i></el-button>
          </el-upload>
          <span style="padding:10px; color: rgb(102,102,102)">750 x 1334</span>
        </el-form-item>
        <el-button @click="submit()" id="layuiadmin-app-form-submit" style="display: none">提交</el-button>
      </el-form>
    </div>
  </div>
</template>

<script>
import {
  TipEnum,
  UrlEnum,
  getLocalProjectId,
  ActivityTypeEnum,
  request
} from "../../../../public/js/common-vue";
import Loading from "@/components/Loading";
import CourseFormItemSingle from "@/components/CourseFormItemSingle";
export default {
  name: "ActivitySave",
  data() {
    return {
      posterWidth: 356,
      posterHeight: 633,
      moveFlag:false,
      qrCode:{
        width:64,
        height:64,
        left:150,
        top:480,
        class:''
      },
      uploadUrl:UrlEnum.UPLOAD_POSTER,
      loading: false,
      loadingText:'',
      dateTime: [new Date(), new Date()],
      lessons:[],
      questions:[],
      id:0,
      sort:'',
      isDateTimeDisabled:false,
      activityTypeEnum:ActivityTypeEnum,
      activity:{
        name:'',
        type:ActivityTypeEnum.PROMOTER,
        volume:'',
        courseId:'',
        integral:'',
        picUrl:'',
        sort:'',
        rewardRate:'',
        qrcodeX:0,
        qrcodeY:0
      }
    }
  },
  components: {CourseFormItemSingle, Loading},
  mounted() {
    if(request('id') != null && request('id') != ''){
      this.id=request('id');
      this.initData();
    }
    else{
      this.$refs.courseFormItemSingle.initData(null,null);
    }
  },
  methods:{
    //坐标转百分比_x
    makeQrcodeX(clientX){
      return clientX * 100 / this.posterWidth;
    },
    //坐标转百分比_y
    makeQrcodeY(clientY){
      return clientY * 100 / this.posterHeight;
    },
    //百分比转坐标_x
    makeClientX(qrcodeX){
      return this.posterWidth * qrcodeX / 100;
    },
    //百分比转坐标_y
    makeClientY(qrcodeY){
      return this.posterHeight * qrcodeY / 100;
    },
    makeLeft(x){
      return x - 47;
    },
    makeTop(y){
      return y - 47;
    },
    down(event) {
      this.moveFlag=true;
      this.qrCode.class='chosen';
    },
    move(event) {
      if(this.moveFlag){
        console.log("move============");
        let left = this.makeLeft(event.clientX);
        let top = this.makeTop(event.clientY);
        let maxLeft = this.posterWidth - this.qrCode.width;
        if(left > maxLeft){ left = maxLeft; }
        let maxTop = this.posterHeight - this.qrCode.height;
        if(top > maxTop){ top = maxTop;}
        if(top < 0){top = 0;}
        if(left < 0){ left = 0;}

        this.qrCode.left = left;
        this.qrCode.top = top;
      }
    },
    up(event){
      this.moveFlag=false;
      this.qrCode.class='';
      this.activity.qrcodeX=this.makeQrcodeX(event.clientX);
      this.activity.qrcodeY=this.makeQrcodeY(event.clientY);
      console.log("clientX:"+event.clientX);
      console.log("clientY:"+event.clientY);

    },
    handleSuccess(response, file, fileList) {
      console.log(response);
      this.activity.picUrl = response.data.src;
    },
    handleChange(val){
        this.$refs.courseFormItemSingle.initData(this.activity.courses,null);
    },
    submit:function (){
      if(this.dateTime == null || this.dateTime == ""){
        this.showMsgError("请选择活动周期");
        return;
      }
      if(this.activity.name == ""){
        this.showMsgError("请输入活动名称");
        return;
      }
      this.activity.courseId = this.$refs.courseFormItemSingle.getCourseId();
      if(this.activity.courseId == null || this.activity.courseId == ""){
        this.showMsgError("请选择课程");
        return;
      }
      if(this.activity.rewardRate == ""){
        this.showMsgError("请输入奖励比例");
        return;
      }
      if(this.activity.picUrl == ""){
        this.showMsgError("请上传海报");
        return;
      }
      this.activity.projectId = getLocalProjectId();

      this.activity.startTime = this.moment(this.dateTime[0]).format("YYYY-MM-DD HH:mm:ss");
      this.activity.endTime = this.moment(this.dateTime[1]).format("YYYY-MM-DD HH:mm:ss");
      if(this.activity.volume == ''){
        this.activity.volume = 1
      }
      this.loadingText="提交中";
      this.loading = true;
      let url = UrlEnum.DISTRIBUTION_ACTIVITIES;
      var method = "post";
      if(this.id != 0){
        url += "/"+this.id;
        method = "put";
      }
      this.$http({
        method: method,
        url: url,
        data: this.activity,
      })
      .then((res) => {
        this.loading=false;
        this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
        setTimeout(function () {
          window.parent.postMessage({
            msg: '',
            key: 'refresh'
          }, '*')
        }, 800);
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
    initData(){
      this.loading = true;
      this.loadingText="加载中";
      this.$http({
        method: 'GET',
        url: UrlEnum.DISTRIBUTION_ACTIVITIES + "/" + request('id'),
        data: '',
      })
      .then((res) => {
        this.loading = false;
        this.activity = res.data;
        let clientX = this.makeClientX(this.activity.qrcodeX);
        let clientY = this.makeClientY(this.activity.qrcodeY);
        console.log("clientX:"+clientX);
        console.log("clientY:"+clientY);
        this.qrCode.left = this.makeLeft(clientX);
        this.qrCode.top = this.makeTop(clientY);
        this.$refs.courseFormItemSingle.initData(this.activity.courseId,null);
        this.dateTime=[this.activity.startTime,this.activity.endTime];
        if(new Date(this.activity.startTime) < new Date()){
          this.isDateTimeDisabled = true;
        }
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
  }
}
</script>

<style scoped>

.div-left{
  float: left;
  background-color: #f1f1f1;
}
.div-right{
  float: right;
  width: calc(100% - 375px);
}
.chosen{
  border:1px dotted #cccccc;
  cursor: move;
}
</style>
